import React, { useState, useEffect, useContext } from "react";

// Libraries
import useQuery from "@hybris-software/use-query";
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Components
import SearchBox from "./Components/SearchBox/SearchBox";
import MenuUserFound from "./Components/MenuUserFound/MenuUserFound";
import UserNotFound from "./Components/UserNotFound/UserNotFound";
import AddCitizenInformation from "./Components/AddCitizenInformation/AddCitizenInformation";
import ViewLoader from "../../../Components/Advanced/ViewLoader/ViewLoader";

// Contexts
import { RoutesContext } from "../../../Contexts/RoutesContext";

// Data
import endpoints from "../../../Data/endpoints";

const DashboardVaccinatoreView = () => {
  const navigate = useNavigate();
  const { paths } = useContext(RoutesContext);

  const [url, setUrl] = useState("");
  const [search, setSearch] = useState("");
  const [addUser, setAddUser] = useState(false);

  const getCitizenApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      console.log(response);
      setUrl(null);
    },
    onError: (error) => {
      setUrl(null);
    },
  });

  useEffect(() => {
    if (search !== "") {
      setUrl(`${endpoints.getCitizen}?fc=${search}`);
    }
  }, [search]);

  useEffect(() => {
    if (url && url !== "" && search !== "" && search) {
      getCitizenApi.executeQuery();
    }
  }, [url]);

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      firstApiLoading={getCitizenApi?.isLoading}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <h4>Ricerca Cittadino</h4>
        <SearchBox
          search={search}
          setSearch={setSearch}
          isLoading={getCitizenApi.isLoading}
          setAddUser={setAddUser}
        />

        {/* IF USER FOUND */}
        {getCitizenApi?.response?.data && (
          <MenuUserFound
            data={getCitizenApi?.response?.data[0]}
            fiscalCode={search}
          />
        )}

        {/* IF USER NOT FOUND */}
        {getCitizenApi?.error?.response &&
          (addUser ? (
            <AddCitizenInformation fiscalCode={search} />
          ) : (
            <UserNotFound setAddUser={setAddUser} />
          ))}
      </Container>
    </PermissionRoute>
  );
};

export default DashboardVaccinatoreView;
