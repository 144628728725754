import React, { useRef } from "react";

//Libraries
import { TableClient } from "@hybris-software/use-table";

// Utils
import { tableStyles, texts } from "../../../../../Utils/tableStyles";

// Styles
import Style from "./VaccinationStatus.module.css";

const VaccinationStatus = ({ data }) => {
  const pathologiesRef = useRef(null);

  let tableData = data.vaccinations.map((vaccination) => {
    return {
      vaccinationId: vaccination.vaccinationId,
      nDose: vaccination.nDose,
      vaccine: vaccination.vaccine.name,
      vaccineType: vaccination.vaccine.vaccineType,
      vaccinationDate: vaccination.vaccinationDate,
    };
  });

  // Sort table data from the most recent vaccination to the oldest, the format is dd-mm-yyyy hh:mm
  tableData.sort((a, b) => {
    let dateA = a.vaccinationDate.split(" ")[0].split("-");
    let dateB = b.vaccinationDate.split(" ")[0].split("-");
    let timeA = a.vaccinationDate.split(" ")[1].split(":");
    let timeB = b.vaccinationDate.split(" ")[1].split(":");
    let dateAObj = new Date(
      dateA[2],
      dateA[1] - 1,
      dateA[0],
      timeA[0],
      timeA[1]
    );
    let dateBObj = new Date(
      dateB[2],
      dateB[1] - 1,
      dateB[0],
      timeB[0],
      timeB[1]
    );
    return dateBObj - dateAObj;
  });

  const columns = [
    {
      Header: "ID Vaccinazione",
      field: "vaccinationId",
      searchable: false,
      orderField: "vaccinationId",
      accessor: (row) => {
        return row.vaccinationId;
      },
    },
    {
      Header: "Vaccino",
      field: "vaccine",
      searchable: false,
      orderField: "vaccine",
      accessor: (row) => {
        return row.vaccine;
      },
    },
    {
      Header: "Dose",
      field: "nDose",
      searchable: false,
      orderField: "nDose",
      accessor: (row) => {
        return row.nDose;
      },
    },
    {
      Header: "Tipo Vaccino",
      field: "vaccineType",
      searchable: false,
      orderField: "vaccineType",
      accessor: (row) => {
        return row.vaccineType;
      },
    },
    {
      Header: "Data Vaccinazione",
      field: "vaccinationDate",
      searchable: false,
      orderField: "vaccinationDate",
      accessor: (row) => {
        return row.vaccinationDate;
      },
    },
  ];

  return (
    <section className={Style.vaccination}>
      <div className={Style.vaccinationInfo}>
        <div>
          Vaccinato:{" "}
          <span className={Style.status}>
            {tableData?.length > 0 ? "Si" : "No"}
          </span>
        </div>
        <div>
          Numero dosi: <span className={Style.doses}>{tableData?.length}</span>
        </div>
        <div>
          Ultima dose:{" "}
          <span className={Style.lastDoseData}>
            {tableData[0]?.vaccinationDate}
          </span>
        </div>
      </div>
      <TableClient
        ref={pathologiesRef}
        Styles={tableStyles}
        enableAllowedActions={false}
        columns={columns}
        rawData={tableData}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enableSelectableRows={false}
        enableSittings={false}
        headerHeight={58}
        rowHeight={60}
        texts={texts}
      />
    </section>
  );
};

export default VaccinationStatus;
