import React, { useRef } from "react";

//Libraries
import { TableClient } from "@hybris-software/use-table";

// Utils
import { tableStyles, texts } from "../../../../../Utils/tableStyles";

// Styles
// import Style from "./Pathologies.module.css";

const Pathologies = () => {
  const pathologiesRef = useRef(null);

  const columns = [
    {
      Header: "Transaction ID",
      field: "id",
      searchable: false,
      orderField: "id",
      accessor: (row) => {
        return "qualcosa";
      },
    },
    {
      Header: "Transaction Type",
      field: "transactionType",
      searchable: false,
      orderField: "transaction_type",
      accessor: (row) => {
        return "qualcosa";
      },
    },
    {
      Header: "From Currency",
      field: "fromCurrency",
      searchable: false,
      orderField: "from_currency",
      accessor: (row) => {
        return "qualcosa";
      },
    },
    {
      Header: "From Amount",
      field: "fromAmount",
      searchable: false,
      orderField: "from_amount",
      accessor: (row) => {
        return "qualcosa";
      },
    },
    {
      Header: "To Currency",
      field: "toCurrency",
      searchable: false,
      orderField: "to_currency",
      accessor: (row) => {
        return "qualcosa";
      },
    },
  ];

  return (
    <TableClient
      ref={pathologiesRef}
      Styles={tableStyles}
      enableAllowedActions={false}
      columns={columns}
      endPoint={""}
      enableSearch={false}
      enableSearchFieldSelect={false}
      enableSelectableRows={false}
      enableSittings={false}
      headerHeight={58}
      rowHeight={60}
      texts={texts}
    />
  );
};

export default Pathologies;
