import React from 'react'

// Icons
import { IoMdNotificationsOutline } from 'react-icons/io'

// Styles
import Style from './Notification.module.css'

const Notification = () => {
    return (
        <div className={Style.notification}>
            <div className={Style.newNotify} />
            <IoMdNotificationsOutline />
        </div>
    )
}

export default Notification