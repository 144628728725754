import React, { useState, useEffect, useRef } from "react";

// Components
import { InputField, Button, MagicModal } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";
import ApiResponse from "../../../../../Components/Advanced/ApiResponse/ApiResponse";

// Data
import endpoints from "../../../../../Data/endpoints";

// Styles
import Style from "./StartSystem.module.css";

const StartSystem = () => {
  // States
  const [url, setUrl] = useState("");

  // Form
  const form = useForm({
    inputs: {
      aldatausr: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      aldataadm: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      kms: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      cydata: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      lks: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
    },
  });

  // Api
  const activateSystemApi = useQuery({
    url: url,
    method: "POST",
    onSuccess: (data) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Successo!"
          description="Sistema attivato con successo"
          buttonAction={() => {
            modalRef.current.destroy();
            window.location.reload();
          }}
        />
      );
    },
    onError: (error) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Errore"
          description={error?.response?.data[0]["Message"]}
          buttonAction={() => modalRef.current.destroy()}
        />
      );
      setUrl(null);
    },
  });

  // Effects
  useEffect(() => {
    if (url && url !== "") {
      activateSystemApi.executeQuery();
    }
  }, [url]);

  // Refs
  const modalRef = useRef(null);

  return (
    <section className={Style.system}>
      <MagicModal ref={modalRef} showCloseIcon={false} />
      <div className={Style.box}>
        <div className={Style.label}>aldatausr</div>
        <InputField
          placeholder="Inserisci aldatausr"
          {...form.getInputProps("aldatausr")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>aldataadm</div>
        <InputField
          placeholder="Inserisci aldataadm"
          {...form.getInputProps("aldataadm")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>kms</div>
        <InputField
          placeholder="Inserisci kms"
          {...form.getInputProps("kms")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>cydata</div>
        <InputField
          placeholder="Inserisci cydata"
          {...form.getInputProps("cydata")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>lks</div>
        <InputField
          placeholder="Inserisci lks"
          {...form.getInputProps("lks")}
        />
      </div>

      <Button
        disabled={!form.isValid()}
        isLoading={activateSystemApi?.isLoading}
        className={Style.create}
        onClick={() => {
          const formData = form.getApiBody();
          const data = Object.keys(formData)
            .map((key) => {
              return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(formData[key])
              );
            })
            .join("&");
          setUrl(endpoints.activateSystem + "?" + data);
        }}
      >
        Conferma
      </Button>
    </section>
  );
};

export default StartSystem;
