import React, { useState } from "react";

// Components
import NavMenu from "../NavMenu/NavMenu";
import InfoCitizen from "../InfoCitizen/InfoCitizen";
import Pathologies from "../Pathologies/Pathologies";
import ReactionEpisode from "../ReactionEpisode/ReactionEpisode";
import VaccinationStatus from "../VaccinationStatus/VaccinationStatus";
import Isolation from "../Isolation/Isolation";
import AddVaccine from "../AddVaccine/AddVaccine";

// Icons
import { BsCalendar3 } from "react-icons/bs";

// Styles
import Style from "./MenuUserFound.module.css";

const MenuUserFound = ({ data, fiscalCode }) => {
  const [activeTab, setActiveTab] = useState("anagrafica");

  return (
    <section className={Style.userFound}>
      <div className={Style.navigation}>
        <div className={Style.userInfomation}>
          <div className={Style.recap}>
            <h5>
              {data?.name} {data?.surname}
            </h5>
            <p>CF: {data?.fiscalCode}</p>
          </div>
          <div className={Style.date}>
            <BsCalendar3 />
            <div>
              Data inserimento
              <p>{data?.registrationDate}</p>
            </div>
          </div>
        </div>

        <NavMenu activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      <div>
        {activeTab === "anagrafica" && <InfoCitizen data={data} />}
        {activeTab === "patologie" && <Pathologies data={data} />}
        {activeTab === "episodio" && <ReactionEpisode data={data} />}
        {activeTab === "vaccinale" && <VaccinationStatus data={data} />}
        {activeTab === "positività" && <Isolation data={data?.covidHistory} />}
        {activeTab === "addVaccino" && <AddVaccine fiscalCode={fiscalCode} data={data} />}
      </div>
    </section>
  );
};

export default MenuUserFound;
