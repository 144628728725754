import React, { useContext } from "react";

// Components
import ActionResearcher from "./Components/ActionResearcher/ActionResearcher";
import ResearcherInformation from "./Components/ResearcherInformation/ResearcherInformation";
import ViewLoader from "../../../Components/Advanced/ViewLoader/ViewLoader";
import VaccinationChart from "./Components/VaccinationChart/VaccinationChart";
import StackBarChart from "./Components/StackBarChart/StackBarChart";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../Contexts/RoutesContext";

const DashboardResearcherView = () => {
  const navigate = useNavigate();
  const { paths } = useContext(RoutesContext);

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <h4>Dashboard Ricercatore</h4>
        <ActionResearcher />
        <ResearcherInformation />
        <VaccinationChart title={"Vaccinazioni"} />
        <StackBarChart title={"Vaccinazioni per tipo"} />
        <VaccinationChart title={"Test PCR"} />
        <StackBarChart title={"Test PCR per tipo"} />
      </Container>
    </PermissionRoute>
  );
};

export default DashboardResearcherView;
