import React from "react";

// Components
import AuthGlobalLoader from "../../../Components/Advanced/AuthGlobalLoader/AuthGlobalLoader";

// Libraries
import { AuthRoute } from "@hybris-software/use-auth";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "@hybris-software/use-auth";

// Images
import VaccinatoreBase from "../../../Assets/images/vaccinatore-base.png";
import CittadinoBase from "../../../Assets/images/cittadino-base.png";

// Icons
import { TiChevronRight } from "react-icons/ti";

// Styles
import Style from "./LoginProfileView.module.css";

const LoginProfileView = () => {
  const { userInfo } = useUser();
  const navigate = useNavigate();

  return (
    <AuthRoute
      loader={<AuthGlobalLoader />}
      minimumLoadingTime={0}
      forLoggedUser={true}
      action={() => {
        navigate("/");
      }}
    >
      <div className={Style.loginProfileCard}>
        <div className={Style.position}>
          <div className={Style.chooses}>
            <h5>Scegli con quale profilo vuoi accedere</h5>
            <div className={Style.boxTypes}>
              <div
                className={Style.typeVaccinatore}
                onClick={() => {
                  navigate("/auth/login-profile/");
                }}
              >
                <div className={Style.sprite}>
                  <img src={VaccinatoreBase} alt="" height={225} />
                  <img
                    className={Style.shapeOne}
                    src={VaccinatoreBase}
                    alt=""
                    height={225}
                  />
                  <img
                    className={Style.shapeTwo}
                    src={VaccinatoreBase}
                    alt=""
                    height={225}
                  />
                </div>
                <h5>
                  {userInfo[0]?.role === "MANAGER"
                    ? "Manager"
                    : userInfo[0]?.role === "RESEARCHER"
                    ? "Ricercatore"
                    : userInfo[0]?.role === "VACCINATOR"
                    ? "Vaccinatore"
                    : ""}
                </h5>
                <Link to="/">
                  <div className={Style.actions}>
                    Continua <TiChevronRight />
                  </div>
                </Link>
              </div>

              <div
                className={Style.typeCittadino}
                onClick={() => {
                  navigate("/auth/login-profile/");
                }}
              >
                <div className={Style.sprite}>
                  <img src={CittadinoBase} alt="" height={225} />
                  <img
                    className={Style.shapeOne}
                    src={CittadinoBase}
                    alt=""
                    height={225}
                  />
                  <img
                    className={Style.shapeTwo}
                    src={CittadinoBase}
                    alt=""
                    height={225}
                  />
                </div>
                <h5>Cittadino</h5>
                <div className={Style.actions}>
                  Continua <TiChevronRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default LoginProfileView;
