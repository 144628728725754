import React from "react";

// Images
import Logo from "../../../Assets/logos/cycad-logo.png";

// Styles
import Style from "./AuthGlobalLoader.module.css";

const AuthGlobalLoader = () => {

    return (
        <div className={Style.globalLoader}>
            <img src={Logo} alt="" />
        </div>
    );
};

export default AuthGlobalLoader;
