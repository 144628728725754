import React from 'react'

//Libraries
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from 'react-chartjs-2';

// Styles
import Style from "./StackBarChart.module.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.color = "#615E83";

const StackBarChart = ({ title }) => {

    const formatBigNumber = Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short", maximumFractionDigits: 2 });

    const options = {
        bezierCurve: false,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
        pointRadius: 0,
        pointHoverRadius: 9,

        pointBackgroundColor: "#4A3AFF",
        scales: {
            x: {
                grid: {
                    display: true,
                    color: "transparent",
                    drawTicks: false,
                },
                stacked: true,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 6,
                },
            },
            y: {
                grid: {
                    color: "rgba(229, 229, 239, 0.16)",
                    drawTicks: false,
                },
                border: {
                    // dash: [7, 10],
                },
                ticks: {
                    callback: function (value) {
                        return formatBigNumber.format(value) + "  ";
                    },
                    maxTicksLimit: 5,
                },
            },
        },
        plugins: {
            filler: {
                propagate: true,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                caretPadding: 20,
                backgroundColor: "#1e1b39",
                bodyColor: "white",
                displayColors: false,
                datalables: false,
                bodyFont: {
                    size: 14,
                    weight: 500,
                },
                caretSize: 8,
                padding: {
                    left: 20,
                    right: 20,
                    top: 7,
                    bottom: 7,
                },
                borderColor: "#1e1b39",
                borderWidth: 1,
                title: false,
                callbacks: {
                    title: () => {
                        return null;
                    },
                    label: (ctx) => {
                        return (
                            `${ctx.raw} ${ctx.dataset.label}`
                        );
                    },
                },
                yAlign: "bottom",
            },
            legend: false,
            title: false,
        },
    };
    const labels = [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
    ];

    const data = {
        labels,
        datasets: [
            {
                label: "Pfizer",
                data: [40, 60, 70, 75, 90, 66],
                borderColor: "#962DFF",
                backgroundColor: "#962DFF",
                tension: 0.4,
                borderRadius: 5,
                fill: true,
                barPercentage: 0.4,
            },
            {
                label: "Jhonson & Jhonson",
                data: [50, 80, 90, 100, 110, 86],
                borderColor: "#C893FD",
                backgroundColor: "#C893FD",
                tension: 0.4,
                borderRadius: 5,
                fill: true,
                barPercentage: 0.4,
            },
            {
                label: "Astrazeneca",
                data: [60, 100, 110, 120, 130, 106],
                borderColor: "#E0C6FD",
                backgroundColor: "#E0C6FD",
                tension: 0.4,
                borderRadius: 5,
                barPercentage: 0.4,
                fill: true,
            },
            {
                label: "Moderna",
                data: [70, 120, 130, 140, 150, 126],
                borderColor: "#F0E5FC",
                backgroundColor: "#F0E5FC",
                tension: 0.4,
                borderRadius: 5,
                barPercentage: 0.4,
                fill: true,
            },
        ],
    };

    return (
        <section className={Style.chart}>
            <div className={Style.header}>
                <div className={Style.left}>
                    <div className={Style.label}>Totale</div>
                    <h6 className={Style.title}>{title}</h6>
                </div>
                <div className={Style.right}>
                    {data.datasets.map((item, index) => {
                        return (
                            <div key={index} className={Style.box}>
                                <div className={Style.circle} style={{ backgroundColor: item.backgroundColor }} />
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={Style.canvasWrapper}>
                <Bar
                    options={options}
                    data={data}
                    style={{ maxWidth: "100%", width: "100%", height: "320px" }}
                />
            </div>

        </section>
    )
}

export default StackBarChart