import React, { useRef } from "react";

// Libraries
import { TableClient } from "@hybris-software/use-table";

// Utils
import { tableStyles, texts } from "../../../../../Utils/tableStyles";

// Styles
import Style from "./Users.module.css";

const Users = ({ data }) => {
  const usersRef = useRef(null);

  const columns = [
    {
      Header: "Codice Fiscale",
      field: "fiscalCode",
      searchable: false,
      orderField: "fiscalCode",
      accessor: (row) => {
        return row.fiscalCode;
      },
    },
    {
      Header: "Data di Registrazione",
      field: "registrationDate",
      searchable: false,
      orderField: "registrationDate",
      accessor: (row) => {
        return row.registrationDate;
      },
    },
  ];

  return (
    <section className={Style.table}>
      <TableClient
        ref={usersRef}
        Styles={tableStyles}
        enableAllowedActions={false}
        columns={columns}
        enableSearch={false}
        enableSearchFieldSelect={false}
        enableSelectableRows={false}
        enableSittings={false}
        headerHeight={58}
        rowHeight={60}
        rawData={data}
        texts={texts}
      />
    </section>
  );
};

export default Users;
