import React, { useContext } from "react";

// Components
import Profile from "../../Advanced/Profile/Profile";

// Images
import Logo from "../../../Assets/logos/cycad-logo.png";

// Context
import UserDetails from "../../../Contexts/UserContext";

// Styles
import Style from "./Navbar.module.css";

const Navbar = ({ setSidebarIsOpen, sidebarIsOpen }) => {

  const user = useContext(UserDetails);

  return (
    <nav className={Style.navbarContainer}>
      <div className={Style.leftSide}>
        <img src={Logo} alt="Logo" draggable={false} height={42} />
        <div className={Style.bar} />
        <div
          className={sidebarIsOpen ? Style.open : Style.menuButton}
          onClick={() => setSidebarIsOpen((old) => !old)}
          title="Toggle sidebar"
        >
          <div className={Style.topBar}></div>
          <div className={Style.middleBar}></div>
          <div className={Style.bottomBar}></div>
        </div>
      </div>

      <div className={Style.rightSide}>
        <Profile user={user} />
      </div>
    </nav>
  );
};

export default Navbar;
