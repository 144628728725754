import React from 'react';

// Components
import ScrollToTop from './Components/BasicUi/ScrollToTop/ScrollToTop';

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Contexts
import { RoutesContext } from "./Contexts/RoutesContext";
import { ThemeProvider } from "./Contexts/ThemeContext";

// Data
import { routeComponents, paths, sidebarRoutes, sidebarRoutesAdmin, sidebarRoutesResearcher } from "./Data/routes";

function App() {
  return (
    <ThemeProvider>
      <RoutesContext.Provider value={{ paths, sidebarRoutes, sidebarRoutesAdmin, sidebarRoutesResearcher }}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {routeComponents}
            <Route path="*" element="404" />
          </Routes>
        </BrowserRouter>
      </RoutesContext.Provider>
    </ThemeProvider>

  );
}

export default App;
