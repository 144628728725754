import React from "react";

// Components
import MouseDrag from "../../../../../Components/Advanced/MouseDrag/MouseDrag";

// Styles
import Style from "./NavMenu.module.css";

const tabs = [
  {
    label: "Anagrafica",
    tab: "anagrafica",
    isDisabled: false,
  },
  {
    label: "Patologie",
    tab: "patologie",
    isDisabled: true,
  },
  {
    label: "Episodio di Reazione",
    tab: "episodio",
    isDisabled: false,
  },
  {
    label: "Stato Vaccinale",
    tab: "vaccinale",
    isDisabled: false,
  },
  {
    label: "Positività e Isolamento",
    tab: "positività",
    isDisabled: false,
  },
  {
    label: "Aggiungi Vaccino",
    tab: "addVaccino",
    isDisabled: false,
  },
];

const NavMenu = ({ activeTab, setActiveTab }) => {
  return (
    <MouseDrag>
      <div className={Style.navbar}>
        {tabs.map(({ label, tab, isDisabled }, index) => {
          return (
            <div
              key={index}
              className={
                !isDisabled
                  ? activeTab === tab
                    ? Style.tabActive
                    : Style.tab
                  : Style.tabDisabled
              }
              onClick={() => {
                if (activeTab !== tab && !isDisabled) setActiveTab(tab);
              }}
            >
              <div>{label}</div>
            </div>
          );
        })}
      </div>
    </MouseDrag>
  );
};

export default NavMenu;
