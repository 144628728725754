import React, { useState } from "react";

// Libraries
import { Link, useLocation } from "react-router-dom";

// Icons
import { FiChevronDown } from "react-icons/fi";
import { CiLogout } from "react-icons/ci";
import { HiOutlineLockClosed } from "react-icons/hi";
import { TbSunMoon } from "react-icons/tb";

// Hooks
import useMediaQuery from "../../../Hooks/useMediaQuery";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./Sidebar.module.css";

const sidebarClass = (sidebarIsOpen, isDesktop) => {
  if (isDesktop) {
    if (sidebarIsOpen) {
      return Style.sidebarOpened;
    } else {
      return Style.sidebar;
    }
  } else {
    if (sidebarIsOpen) {
      return Style.sidebarOpened;
    } else {
      return Style.sidebarMobile;
    }
  }
};

const dropdownLabelClass = (routeBasePath, location) => {
  if (location.pathname === routeBasePath) return Style.elementActive;
  else if (location.pathname.split("/")[1] === routeBasePath.split("/")[1])
    return Style.elementActive;
  else return Style.element;
};

const linkLabelClass = (isOpen) => {
  if (isOpen) return Style.mainCategoryOpenCollapsed;
  else return Style.mainCategory;
};

const Sidebar = ({
  route,
  theme,
  setTheme,
  sidebarIsOpen,
  setSidebarIsOpen,
}) => {
  // Constants
  const isDesktop = useMediaQuery("(min-width: 991px)");

  const [openDropdowns, setOpenDropdowns] = useState(
    Object.keys(route).reduce((acc, key) => {
      acc[key] = route[key].routes.map(() => false);
      return acc;
    }, {})
  );

  const setDropdownOpen = (index, category, open) => {
    setOpenDropdowns((oldOpenDropdowns) => {
      const newOpenDropdowns = { ...oldOpenDropdowns };
      newOpenDropdowns[category][index] = open;
      return newOpenDropdowns;
    });
  };

  return (
    <div className={sidebarClass(sidebarIsOpen, isDesktop)}>
      {/* Top */}
      <div className={Style.boxAvatar}></div>

      {/* Center */}
      <div className={Style.boxNav}>
        <nav>
          {Object.keys(route).map((category, i) => {
            return (
              <div key={i} className={Style.categoryBox}>
                {route[category].showInSideBar &&
                  (sidebarIsOpen || !isDesktop) && (
                    <div className={Style.labelCategory}>
                      <div className={Style.category}>
                        {route[category].categoryTitle}
                      </div>
                      <div className={Style.categoryLine}></div>
                    </div>
                  )}
                <ul>
                  {route[category].routes.map((el, j) => {
                    if (el.comingSoon) {
                      return <NavSimpleNotAllowed key={j} route={el} />;
                    } else {
                      if (el.routes) {
                        return (
                          <NavMenu
                            key={j}
                            route={el}
                            basePath={el.path}
                            isOpen={openDropdowns[category][j]}
                            setOpen={(open) =>
                              setDropdownOpen(j, category, open)
                            }
                            sidebarIsOpen={sidebarIsOpen}
                            setSidebarIsOpen={setSidebarIsOpen}
                            isDesktop={isDesktop}
                          />
                        );
                      } else {
                        return (
                          <NavSimple
                            key={j}
                            route={el}
                            isDesktop={isDesktop}
                            setSidebarIsOpen={setSidebarIsOpen}
                          />
                        );
                      }
                    }
                  })}
                </ul>
              </div>
            );
          })}
        </nav>
      </div>

      {/* Bottom */}
      <div className={Style.containerBox}>
        <div
          className={Style.footerSidebar}
          onClick={() => {
            theme === "dark" ? setTheme("light") : setTheme("dark");
            localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
          }}
        >
          <div className={Style.iconFooter}>
            <TbSunMoon />
          </div>
          <div className={Style.labelFooter}>Cambio tema</div>
        </div>
        <div
          className={Style.footerSidebar}
          onClick={() => {
            window.location.replace("https://10.8.0.1/api/spid/logout");
          }}
        >
          <div className={Style.iconFooter}>
            <CiLogout />
          </div>
          <div className={Style.labelFooter}>Logout</div>
        </div>
      </div>
    </div>
  );
};

const NavSimple = ({ route, isDesktop, setSidebarIsOpen }) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li
      className={dropdownLabelClass(routeBasePath, location)}
      onClick={() => {
        !isDesktop && setSidebarIsOpen(false);
      }}
    >
      <Link to={routeBasePath}>
        <LabelLinkSimple icon={route.icon} title={route.title} />
      </Link>
    </li>
  );
};

const NavSimpleNotAllowed = ({ route }) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li
      className={dropdownLabelClass(routeBasePath, location)}
      style={{ cursor: "not-allowed" }}
    >
      <Link>
        <LabelLinkSimpleNotAllowed
          icon={route.icon}
          title={route.title}
          style={{ cursor: "not-allowed" }}
        />
      </Link>
    </li>
  );
};

const LabelLinkSimple = ({ icon, title }) => {
  return (
    <div className={Style.mainCategory}>
      <div className={Style.innerCategory}>
        <div className={Style.iconWrapper}>{icon}</div>
        <div className={Style.categoryText}>{title}</div>
      </div>
    </div>
  );
};

const LabelLinkSimpleNotAllowed = ({ icon, title }) => {
  return (
    <div
      className={Style.mainCategory}
      style={{ cursor: "not-allowed", position: "relative", opacity: 0.6 }}
    >
      <div className={Style.innerCategory} style={{ cursor: "not-allowed" }}>
        <div className={Style.iconWrapper}>{icon}</div>
        <div className={Style.categoryText} style={{ cursor: "not-allowed" }}>
          {title}
        </div>
      </div>
      <div className={Style.lock}>
        <HiOutlineLockClosed />
      </div>
    </div>
  );
};

const NavMenu = ({
  route,
  isOpen,
  setOpen,
  setSidebarIsOpen,
  isDesktop,
  sidebarIsOpen,
}) => {
  const location = useLocation();
  const routeBasePath = `/${route.path}`;

  return (
    <li className={dropdownLabelClass(routeBasePath, location)}>
      <LabelLink
        icon={route.icon}
        routes={route.routes}
        title={route.title}
        isOpen={isOpen}
        setOpen={setOpen}
        setSidebarIsOpen={setSidebarIsOpen}
      />
      <Dropdown
        isOpen={isOpen}
        routes={route.routes}
        basePath={routeBasePath}
        isDesktop={isDesktop}
        setSidebarIsOpen={setSidebarIsOpen}
        sidebarIsOpen={sidebarIsOpen}
      />
    </li>
  );
};

const LabelLink = ({
  icon,
  routes,
  title,
  isOpen,
  setOpen,
  setSidebarIsOpen,
}) => {
  return (
    <div
      className={linkLabelClass(isOpen)}
      onClick={() => {
        setOpen(!isOpen);
        setSidebarIsOpen(true);
      }}
    >
      <div className={Style.innerCategory}>
        <div className={Style.iconWrapper}>{icon}</div>
        <div className={Style.categoryText}>{title}</div>
      </div>
      {routes && (
        <FiChevronDown
          className={classNames(Style.arrow, {
            class: Style.arrowActive,
            condition: isOpen,
          })}
        />
      )}
    </div>
  );
};

const Dropdown = ({
  isOpen,
  routes,
  basePath,
  isDesktop,
  setSidebarIsOpen,
  sidebarIsOpen,
}) => {
  const routesLength = Object.entries(routes).filter(
    ([val]) => !val.comingSoon
  ).length;

  return (
    <ul
      style={{
        height: isOpen && sidebarIsOpen ? `${routesLength * 42}px` : "0px",
      }}
      className={classNames(Style.closeDropdown, {
        class: Style.active,
        condition: isOpen,
      })}
    >
      {Object.entries(routes).map(([key, val]) => {
        return (
          <DropdownElement
            key={key}
            route={val}
            basePath={basePath}
            isDesktop={isDesktop}
            setSidebarIsOpen={setSidebarIsOpen}
          />
        );
      })}
    </ul>
  );
};

const DropdownElement = ({ route, basePath, isDesktop, setSidebarIsOpen }) => {
  const location = useLocation();
  const routeBasePath = `${basePath}${route.path}`;

  return (
    <li
      onClick={() => {
        !isDesktop && setSidebarIsOpen(false);
      }}
    >
      <div className={Style.subCategory}>
        <div className={Style.innerSub}>
          {route.comingSoon === true ? null : (
            <Link to={routeBasePath}>
              <div
                className={
                  location.pathname === routeBasePath
                    ? Style.innerElementActive
                    : Style.innerElement
                }
              >
                {route.title}
              </div>
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

export default Sidebar;
