import React, { useState, useEffect, useRef } from "react";

// Libraries
import {
  InputField,
  Button,
  Select,
  MagicModal,
} from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Components
import ApiResponse from "../../../../../Components/Advanced/ApiResponse/ApiResponse";

// Data
import endpoints from "../../../../../Data/endpoints";

// Styles
import Style from "./AddCitizenInformation.module.css";

const AddCitizenInformation = ({ fiscalCode }) => {
  // States
  const [url, setUrl] = useState("");

  // Refs
  const modalRef = useRef();

  // Form
  const form = useForm({
    inputs: {
      name: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      surname: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      fiscalcode: {
        value: fiscalCode,
        formatter: (value) => {
          return value.toUpperCase();
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          const regex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
          if (!regex.test(value)) {
            return [false, "Codice fiscale non valido"];
          }
          return [true, ""];
        },
      },
      borndate: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      gender: {
        value: {
          value: "M",
          label: "Maschio",
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      role: {
        value: {
          value: "U",
          label: "Vaccinatore",
        },
      },

      p_country: {
        value: "Abruzzo",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      p_municipality: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      p_province: {
        formatter: (value) => {
          // only 2 capital letters
          value = value.toUpperCase();
          return value.replace(/[^A-Z]/g, "").slice(0, 2);
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      p_street: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      p_snum: {
        formatter: (value) => {
          // only numbers
          return value.replace(/\D/g, "");
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      p_zipcode: {
        formatter: (value) => {
          // only numbers 5 digits
          return value.replace(/\D/g, "").slice(0, 5);
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          if (value.length < 5) {
            return [false, "Il numero civico deve essere di 5 cifre"];
          }
          return [true, ""];
        },
      },

      structure: {
        value: "",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      s_country: {
        value: "Abruzzo",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      s_municipality: {
        value: "",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      s_province: {
        value: "",
        formatter: (value) => {
          // only 2 capital letters
          value = value.toUpperCase();
          return value.replace(/[^A-Z]/g, "").slice(0, 2);
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      s_street: {
        value: "",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      s_snum: {
        value: "",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
        formatter: (value) => {
          // only numbers
          return value.replace(/\D/g, "");
        },
      },
      s_zipcode: {
        value: "",
        formatter: (value) => {
          // only numbers 5 digits
          return value.replace(/\D/g, "").slice(0, 5);
        },
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          if (value && value.length < 5) {
            return [false, "Il numero civico deve essere di 5 cifre"];
          }
          return [true, ""];
        },
      },
    },
  });

  // Api
  const addUserApi = useQuery({
    url: url,
    method: "POST",
    onSuccess: (response) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Utente creato con successo"
          description="L'utente è stato creato con successo"
          buttonAction={() => modalRef.current.destroy()}
        />
      );
      setUrl(null);
      form.reset();
    },
    onError: (error) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Errore"
          description={error?.response?.data[0]["Message"]}
          buttonAction={() => modalRef.current.destroy()}
        />
      );
      setUrl(null);
    },
  });

  // Effects
  useEffect(() => {
    if (url && url !== "") {
      addUserApi.executeQuery();
    }
  }, [url]);

  return (
    <section className={Style.system}>
      <MagicModal ref={modalRef} />

      <h6>Informazioni Generali</h6>
      <div className={Style.duoBox}>
        <InputField
          label="Nome"
          placeholder="Mario"
          {...form.getInputProps("name")}
        />
        <InputField
          label="Cognome"
          placeholder="Rossi"
          {...form.getInputProps("surname")}
        />
        <InputField
          label="Codice fiscale"
          placeholder="MRRRSS00A00A000A"
          {...form.getInputProps("fiscalcode")}
        />
        <InputField
          label="Data di nascita"
          type="date"
          {...form.getInputProps("borndate")}
        />
        <div className={Style.boxSelect}>
          <div className={Style.label}>Genere</div>
          <Select
            items={[
              {
                value: "M",
                label: "Maschio",
              },
              {
                value: "F",
                label: "Femmina",
              },
            ]}
            placeholder="Seleziona il genere dell'utente"
            {...form.getInputProps("gender")}
          />
        </div>
      </div>

      <h6>Informazioni Personali</h6>
      <div className={Style.duoBox}>
        <div className={Style.boxSelect}>
          <div className={Style.label}>Regione</div>
          <Select
            maxHeightOpened={300}
            items={[
              "Abruzzo",
              "Basilicata",
              "Calabria",
              "Campania",
              "Emilia-Romagna",
              "Friuli-Venezia Giulia",
              "Lazio",
              "Liguria",
              "Lombardia",
              "Marche",
              "Molise",
              "Piemonte",
              "Puglia",
              "Sardegna",
              "Sicilia",
              "Toscana",
              "Trentino-Alto Adige",
              "Umbria",
              "Valle d'Aosta",
              "Veneto",
            ]}
            placeholder="Seleziona la regione di residenza"
            {...form.getInputProps("p_country")}
          />
        </div>
        <InputField
          label="Comune"
          placeholder="Verona"
          {...form.getInputProps("p_municipality")}
        />
        <InputField
          label="Provincia"
          placeholder="VR"
          {...form.getInputProps("p_province")}
        />
        <InputField
          label="Via"
          placeholder="Via san marco"
          {...form.getInputProps("p_street")}
        />
        <InputField
          label="Numero civico"
          placeholder="10"
          {...form.getInputProps("p_snum")}
        />
        <InputField
          label="Codice postale"
          placeholder="37138"
          {...form.getInputProps("p_zipcode")}
        />
      </div>

      <h6>Informazioni Struttura (Opzionali)</h6>
      <div style={{ borderBottom: "none" }} className={Style.duoBox}>
        <InputField
          label="Struttura"
          placeholder="Casa della salute navile"
          {...form.getInputProps("structure")}
        />
        <div className={Style.boxSelect}>
          <div className={Style.label}>Regione</div>
          <Select
            maxHeightOpened={300}
            items={[
              "Abruzzo",
              "Basilicata",
              "Calabria",
              "Campania",
              "Emilia-Romagna",
              "Friuli-Venezia Giulia",
              "Lazio",
              "Liguria",
              "Lombardia",
              "Marche",
              "Molise",
              "Piemonte",
              "Puglia",
              "Sardegna",
              "Sicilia",
              "Toscana",
              "Trentino-Alto Adige",
              "Umbria",
              "Valle d'Aosta",
              "Veneto",
            ]}
            placeholder="Seleziona la regione della struttura"
            {...form.getInputProps("s_country")}
          />
        </div>
        <InputField
          label="Comune"
          placeholder="Bologna"
          {...form.getInputProps("s_municipality")}
        />
        <InputField
          label="Provincia"
          placeholder="BO"
          {...form.getInputProps("s_province")}
        />
        <InputField
          label="Via"
          placeholder="Via domenico svampa"
          {...form.getInputProps("s_street")}
        />
        <InputField
          label="Numero civico"
          placeholder="8"
          {...form.getInputProps("s_snum")}
        />
        <InputField
          label="Codice postale"
          placeholder="40129"
          {...form.getInputProps("s_zipcode")}
        />
      </div>

      <Button
        className={Style.createButton}
        disabled={!form.isValid()}
        isLoading={addUserApi?.isLoading}
        onClick={() => {
          let formData = form.getApiBody();
          formData.borndate = formData.borndate.split("-").reverse().join("-");
          formData.gender = formData.gender.value;
          formData.role = formData.role.value;

          const data = Object.keys(formData)
            .map((key) => {
              return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(formData[key])
              );
            })
            .join("&");
          setUrl(endpoints.addUser + "?" + data);
        }}
      >
        Crea
      </Button>
    </section>
  );
};

export default AddCitizenInformation;
