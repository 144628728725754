import React, { useRef } from "react";

//Libraries
import { TableClient } from "@hybris-software/use-table";

// Utils
import { tableStyles, texts } from "../../../../../Utils/tableStyles";

// Styles
// import Style from "./Isolation.module.css";

const Isolation = ({ data }) => {
  const pathologiesRef = useRef(null);

  const columns = [
    {
      Header: "ID",
      field: "covidId",
      searchable: false,
      accessor: (row) => {
        return row.covidId;
      },
    },
    {
      Header: "Data Inizio",
      field: "covidStartDate",
      searchable: false,
      accessor: (row) => {
        return row.covidStartDate;
      },
    },
    {
      Header: "Data Fine",
      field: "covidEndDate",
      searchable: false,
      accessor: (row) => {
        return row.covidEndDate;
      },
    },
    {
      Header: "Sintomi",
      field: "symptoms",
      searchable: false,
      accessor: (row) => {
        return row.symptoms;
      },
    },
    {
      Header: "Dettagli",
      field: "details",
      searchable: false,
      accessor: (row) => {
        return row.details;
      },
    },
    {
      Header: "Ricoverato",
      field: "isHospitalized",
      searchable: false,
      accessor: (row) => {
        return row.isHospitalized ? "Si" : "No";
      },
    },
    {
      Header: "Regione",
      field: "country",
      searchable: false,
      accessor: (row) => {
        return row.country;
      },
    },
    {
      Header: "Provincia",
      field: "province",
      searchable: false,
      accessor: (row) => {
        return row.province;
      },
    },
  ];

  return (
    <TableClient
      ref={pathologiesRef}
      Styles={tableStyles}
      enableAllowedActions={false}
      columns={columns}
      rawData={data}
      enableSearch={false}
      enableSearchFieldSelect={false}
      enableSelectableRows={false}
      enableSittings={false}
      headerHeight={58}
      rowHeight={60}
      texts={texts}
    />
  );
};

export default Isolation;
