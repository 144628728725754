import React from "react";

// Images
import Success from "../../../../../Assets/images/success.png";

// Styles
import Style from "./SystemActive.module.css";

const SystemActive = () => {
  return (
    <section className={Style.systemActive}>
      <div className={Style.header}>
        <img src={Success} alt="" width={50} />
        <div className={Style.info}>
          <h6>Sistema</h6>
          <p>Il sistema è stato correttamente attivato</p>
        </div>
      </div>
    </section>
  );
};

export default SystemActive;
