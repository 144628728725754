import React, { useContext } from "react";

// Components
import Users from "./Components/Users/Users";

// Libraries
import { Button, Container } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";
import { PermissionRoute } from "@hybris-software/use-auth";

// Data
import endpoints from "../../../Data/endpoints";

// Contexts
import { RoutesContext } from "../../../Contexts/RoutesContext";

// Styles
import Style from "./UsersAdminView.module.css";
import ViewLoader from "../../../Components/Advanced/ViewLoader/ViewLoader";

const UsersAdminView = () => {
  const navigate = useNavigate();
  const { paths } = useContext(RoutesContext);

  const getCitizenApi = useQuery({
    url: endpoints.getAllCitizens,
    method: "GET",
    executeImmediately: true,
  });

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      apiLoading={getCitizenApi?.isLoading}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <>
          <div className={Style.header}>
            <h4>Utenti</h4>
            <Button
              className={Style.button}
              onClick={() => {
                navigate(paths.mainAdmin.addUser);
              }}
            >
              Aggiungi Utente
            </Button>
          </div>
          <Users data={getCitizenApi?.response?.data} />
        </>
      </Container>
    </PermissionRoute>
  );
};

export default UsersAdminView;
