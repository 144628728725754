import React, { useContext } from "react";

// Components
import Profile from "./Components/Profile/Profile";
import ViewLoader from "../../../Components/Advanced/ViewLoader/ViewLoader";


// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Contexts
import { RoutesContext } from "../../../Contexts/RoutesContext";

const GenericProfileView = () => {

  const navigate = useNavigate();
  const { paths } = useContext(RoutesContext);


  return (
    <PermissionRoute
      loader={<ViewLoader />}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <h4>Profilo</h4>
        <Profile />
      </Container>
    </PermissionRoute>
  );
};

export default GenericProfileView;
