import React, { useRef } from "react";

//Libraries
import { TableClient } from "@hybris-software/use-table";

// Utils
import { tableStyles, texts } from "../../../../../Utils/tableStyles";

// Styles
// import Style from "./ReactionEpisode.module.css";

const ReactionEpisode = ({ data }) => {
  const pathologiesRef = useRef(null);

  const reactions = data.vaccinations
    .map((vaccination) => {
      if (vaccination.reactions.length > 0) {
        return vaccination.reactions;
      } else {
        return [];
      }
    })
    .map((reactions) => {
      return reactions.flat();
    })
    .flat();

  const columns = [
    {
      Header: "ID Reazione",
      field: "reactionId",
      searchable: false,
      orderField: "reactionId",
      accessor: (row) => {
        return row.reactionId;
      },
    },
    {
      Header: "Reazione",
      field: "reaction",
      searchable: false,
      orderField: "reaction",
      accessor: (row) => {
        return row.reaction;
      },
    },
    {
      Header: "Dettaglio",
      field: "details",
      searchable: false,
      orderField: "details",
      accessor: (row) => {
        return row.details;
      },
    },
    {
      Header: "Data Inizio",
      field: "startDate",
      searchable: false,
      orderField: "startdate",
      accessor: (row) => {
        return row.startDate;
      },
    },
    {
      Header: "Data Fine",
      field: "endDate",
      searchable: false,
      orderField: "endDate",
      accessor: (row) => {
        return row.endDate;
      },
    },
  ];

  return (
    <TableClient
      ref={pathologiesRef}
      Styles={tableStyles}
      enableAllowedActions={false}
      columns={columns}
      rawData={reactions}
      enableSearch={false}
      enableSearchFieldSelect={false}
      enableSelectableRows={false}
      enableSittings={false}
      headerHeight={58}
      rowHeight={60}
      texts={texts}
    />
  );
};

export default ReactionEpisode;
