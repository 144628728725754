import React from 'react'

// Styles
import Style from "./ResearcherInformation.module.css";

const ResearcherInformation = () => {
    return (
        <section className={Style.information}>
            <div className={Style.grid}>

                <div className={Style.box}>
                    <div className={Style.title}>Utenti totali</div>
                    <h6 className={Style.amount}>
                        <div className={Style.number}>{"1,112,665.00"}</div>
                        <div className={Style.percentage}>{"+24%"}</div>
                    </h6>
                </div>

                <div className={Style.box}>
                    <div className={Style.title}>Episodio di reazione</div>
                    <h6 className={Style.amount}>
                        <div className={Style.number}>{"1,512,748.00"}</div>
                        <div className={Style.percentage}>{"+24%"}</div>
                    </h6>
                </div>

                <div className={Style.box}>
                    <div className={Style.title}>PCT Test totali</div>
                    <h6 className={Style.amount}>
                        <div className={Style.number}>{"7,341,852.00"}</div>
                        <div className={Style.percentage}>{"+24%"}</div>
                    </h6>
                </div>

                <div className={Style.box}>
                    <div className={Style.title}>Vaccinationi totali</div>
                    <h6 className={Style.amount}>
                        <div className={Style.number}>{"5,212,748.00"}</div>
                        <div className={Style.percentageLoss}>{"-18%"}</div>
                    </h6>
                </div>

            </div>
        </section>
    )
}

export default ResearcherInformation