import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Styles
import Style from "./ApiResponse.module.css";

/**
 * @param {string} icon - Icon path
 * @param {string} title - Title text
 * @param {string} description - Text paragraph
 * @param {string} children - Children components
 * @param {number} iconWidth - Icon width
 * @param {string} buttonText - Button text
 * @param {function} buttonAction - Button action function
 */

const ApiResponse = ({
  icon,
  title,
  description,
  children,
  iconWidth = 50,
  buttonText = "Chiudi",
  buttonAction,
}) => {
  return (
    <div className={Style.response}>
      {icon && <img src={icon} alt="" width={iconWidth} />}
      {title && <h5>{title}</h5>}
      {description && <p>{description}</p>}
      {children}

      {buttonText && (
        <Button
          className={Style.close}
          isLoading={false}
          disabled={false}
          onClick={() => buttonAction()}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default ApiResponse;
