// Styles
import Theme from "../theme.module.css";

const theme = {
    container: { containerClassName: Theme.container },
    button: {
        buttonClassName: Theme.button,
        buttonDisabledClassName: Theme.buttonDisabled,
    },
    inputField: {
        baseClassName: Theme.inputBase,
        errorClassName: Theme.inputError,
        successClassName: Theme.inputSuccess,
        errorMessageClassName: Theme.inputErrorMessage,
        labelClassName: Theme.inputLabel,
    },
    select: {
        selectClassName: Theme.selectClass,
        selectPlaceholderClassName: Theme.selectPlaceholderClass,
        selectOpenedClassName: Theme.selectOpenedClass,
        selectOptionClassName: Theme.selectOptionClass,
    },
    magicModal: {
        modalClassName: Theme.modal,
        contentClassName: Theme.modalContent,
    }
};

export default theme;
