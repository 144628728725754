import { Route } from "react-router-dom";

// Icons
import { RxDashboard } from "react-icons/rx";
import { BiUser } from "react-icons/bi";
import { HiUsers, HiUserAdd } from "react-icons/hi";
import { AiOutlinePoweroff } from "react-icons/ai";

// Auth
import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
import LoginView from "../Views/Auth/LoginView/LoginView";
import LoginProfileView from "../Views/Auth/LoginProfileView";

// Main
import MainLayoutVax from "../Layouts/MainLayout/MainLayoutVax";
import DashboardVaxView from "../Views/Main/DashboardVaxView";
import MainLayoutAdmin from "../Layouts/MainLayout/MainLayoutAdmin";
import StartSystemAdminView from "../Views/Main/StartSystemAdminView";
import UsersAdminView from "../Views/Main/UsersAdminView/UsersAdminView";
import AddUserAdminView from "../Views/Main/AddUserAdminView/AddUserAdminView";
import GenericProfileView from "../Views/Main/GenericProfileView";
import MainLayoutResearcher from "../Layouts/MainLayout/MainLayoutResearcher";
import DashboardResearcherView from "../Views/Main/DashboardResearcherView/DashboardResearcherView";

const routes = {
  auth: {
    path: "/auth",
    element: <AuthLayout />,
    title: "Authentication",
    routes: {
      login: {
        path: "/login-profile",
        element: <LoginView />,
        title: "Authenticazione",
        icon: null,
      },
      loginProfile: {
        path: "/login",
        element: <LoginProfileView />,
        title: "Scegli il profilo",
        icon: null,
      },
    },
  },
  mainVaccinator: {
    path: "/",
    element: <MainLayoutVax />,
    title: "Main",
    routes: {
      dashboard: {
        path: "",
        element: <DashboardVaxView />,
        title: "Dashboard",
        icon: <RxDashboard />,
      },
      profile: {
        path: "profile",
        element: <GenericProfileView />,
        title: "Profilo",
        icon: <BiUser />,
      },
    },
  },
  mainAdmin: {
    path: "/",
    element: <MainLayoutAdmin />,
    title: "Main",
    routes: {
      system: {
        path: "system",
        element: <StartSystemAdminView />,
        title: "Abilita Sistema",
        icon: <AiOutlinePoweroff />,
      },
      users: {
        path: "users",
        element: <UsersAdminView />,
        title: "Utenti",
        icon: <HiUsers />,
      },
      addUser: {
        path: "add-user",
        element: <AddUserAdminView />,
        title: "Aggiungi utente",
        icon: <HiUserAdd />,
      },
      profile: {
        path: "admin-profile",
        element: <GenericProfileView />,
        title: "Profilo",
        icon: <BiUser />,
      }
    },
  },
  mainResearcher: {
    path: "/",
    element: <MainLayoutResearcher />,
    title: "Main",
    routes: {
      dashboard: {
        path: "researcher-dashboard",
        element: <DashboardResearcherView />,
        title: "Dashboard",
        icon: <RxDashboard />,
      },
      profile: {
        path: "researcher-profile",
        element: <GenericProfileView />,
        title: "Profilo",
        icon: <BiUser />,
      }
    },
  }
};

function generatePaths(routes, parentPath = "") {
  const paths = {};
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path);
    } else {
      paths[routeName] = parentPath + route.path;
    }
  });
  return paths;
}

function generateRouteComponents(routes, parentPath = "") {
  if (parentPath === "") {
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      );
    });
  } else {
    const views = [];

    Object.keys(routes).forEach((routeName, index) => {
      if (!routes[routeName].comingSoon) {
        views.push(
          <Route
            key={index}
            path={parentPath + routes[routeName].path}
            element={routes[routeName].element}
          />
        );
      }

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        );
      }
    });
    return views;
  }
}

const sidebarCategories = [
  {
    id: "vaccinator",
    categoryTitle: "Category 1",
    showInSideBar: false,
    routes: [
      routes.mainVaccinator.routes.dashboard,
      routes.mainVaccinator.routes.profile,
    ],
  },
];

const sidebarCategoriesAdmin = [
  {
    id: "admin",
    categoryTitle: "Category 1",
    showInSideBar: false,
    routes: [
      routes.mainAdmin.routes.system,
      routes.mainAdmin.routes.users,
      routes.mainAdmin.routes.addUser,
      routes.mainAdmin.routes.profile,
    ],
  },
];

const sidebarCategoriesResearcher = [
  {
    id: "researcher",
    categoryTitle: "Category 1",
    showInSideBar: false,
    routes: [
      routes.mainResearcher.routes.dashboard,
      routes.mainResearcher.routes.profile,
    ],
  },
];

function generatePathsWithCategoriesForSidebar(sidebarCategories) {
  const paths = {};
  sidebarCategories.forEach((category) => {
    paths[category.id] = {
      showInSideBar: category.showInSideBar,
      categoryTitle: category.categoryTitle,
      routes: category.routes,
    };
  });
  return paths;
}

const paths = generatePaths(routes);
const routeComponents = generateRouteComponents(routes);
const sidebarRoutes = generatePathsWithCategoriesForSidebar(sidebarCategories);
const sidebarRoutesAdmin = generatePathsWithCategoriesForSidebar(sidebarCategoriesAdmin);
const sidebarRoutesResearcher = generatePathsWithCategoriesForSidebar(sidebarCategoriesResearcher);
export { routes, paths, routeComponents, sidebarRoutes, sidebarRoutesAdmin, sidebarRoutesResearcher };
