import styled from 'styled-components';

const tableStyles = styled.div`
padding: 0;
  background-color: var(--light);
  padding: 25px;
  border-radius: 5px;
  transition: all 0.5s ease;

  table {
    width: 100%;
    border-spacing: 0;
    thead {
      background-color: var(--light-bg);
      transition: all 0.5s ease;
      border-radius: 4px;
      tr {
        th {
          color: var(--text-secondary-opacity);
          font-weight: 500;
          font-size: 16px;
          min-width: 270px;
          max-width: 800px;
          text-align: center;
          padding: 0 30px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-weight: 500;
          font-size: 16px;
          color: var(--text-secondary-opacity);
          min-width: 270px;
          max-width: 800px;
          text-align: center;
          padding: 0 30px;
          &:nth-child(odd) {
            border-left: 1px solid var(--light-bg);
            border-right: 1px solid var(--light-bg);
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
`;

const texts = {
  actionSelect: "Seleziona un'azione",
  buttonAction: "Applica",
  columnsSelect: "Seleziona una colonna",
  placeholderSearch: "Cerca...",
  settingTitle: "Visibilità colonne",
  rowsSelected: "row(s) selected",
  columnByAsc: "Ordine crescente",
  columnByDesc: "Ordine decrescente",
  hideColumn: "Nascondi colonna",
  showColumns: "Mostra tutte le colonne",
  pageLabel: "Pagina",
  ofPageLabel: "di",
  buttonPrevious: "Precedente",
  buttonNext: "Successiva",
  copyToClipboard: "Copia",
  copied: "Copiato",
}

export { tableStyles, texts };
