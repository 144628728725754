import React from "react";

//Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Styles
import Style from "./VaccinationChart.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

ChartJS.defaults.font.size = 14;
ChartJS.defaults.color = "#615E83";

const VaccinationChart = ({ title }) => {
  const formatBigNumber = Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 2,
  });

  const options = {
    bezierCurve: false,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    pointRadius: 0,
    pointHoverRadius: 9,

    pointBackgroundColor: "#4A3AFF",
    scales: {
      x: {
        grid: {
          display: true,
          color: "transparent",
          drawTicks: false,
        },
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 6,
        },
      },
      y: {
        grid: {
          color: "rgba(229, 229, 239, 0.16)",
          drawTicks: false,
        },
        border: {
          // dash: [7, 10],
        },
        ticks: {
          callback: function (value) {
            return formatBigNumber.format(value) + "  ";
          },
          maxTicksLimit: 5,
        },
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        caretPadding: 20,
        backgroundColor: "#1e1b39",
        bodyColor: "white",
        displayColors: false,
        datalables: false,
        bodyFont: {
          size: 14,
          weight: 500,
        },
        caretSize: 8,
        padding: {
          left: 20,
          right: 20,
          top: 7,
          bottom: 7,
        },
        borderColor: "#1e1b39",
        borderWidth: 1,
        title: false,
        callbacks: {
          title: () => {
            return null;
          },
          label: (ctx) => {
            return `${ctx.raw} vaccinazioni`;
          },
        },
        yAlign: "bottom",
      },
      legend: false,
      title: false,
    },
  };
  const labels = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago"];

  const data = {
    labels,
    datasets: [
      {
        label: false,
        data: [1, 7, 3, 2, 4, 5, 1, 4],
        borderColor: "#4A3AFF",
        backgroundColor: "rgba(74, 58, 255, 0.1)",
        tension: 0.4,
        fill: true,
      },
    ],
  };

  return (
    <section className={Style.chart}>
      <div className={Style.header}>
        <div className={Style.left}>
          <div className={Style.label}>Totale</div>
          <h6 className={Style.title}>{title}</h6>
          <p className={Style.info}>
            <span className={Style.percentage}>+1.3%</span> dall'anno scorso
          </p>
        </div>
      </div>
      <div className={Style.canvasWrapper}>
        <Line
          options={options}
          data={data}
          style={{ maxWidth: "100%", width: "100%", height: "320px" }}
        />
      </div>
    </section>
  );
};

export default VaccinationChart;
