import React, { useState, useContext } from "react";

// Components
import Navbar from "../../Components/BasicUi/Navbar/Navbar";
import Sidebar from "../../Components/BasicUi/Sidebar/Sidebar";
import GlobalLoader from "../../Components/Advanced/GlobalLoader/GlobalLoader";

// Librairies
import { Outlet, useNavigate } from "react-router-dom";
import { PermissionRoute, useUser } from "@hybris-software/use-auth";

// Contexts
import { RoutesContext } from "../../Contexts/RoutesContext";
import { ThemeContext } from "../../Contexts/ThemeContext";
import UserDetails from "../../Contexts/UserContext";

// Styles
import Style from "./MainLayout.module.css";

const MainLayoutResearcher = () => {
  const { userInfo } = useUser();

  //Hook
  const navigate = useNavigate();
  //Contexts
  const { sidebarRoutesResearcher, paths } = useContext(RoutesContext);
  const { theme, setTheme } = useContext(ThemeContext);
  // States
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const closeSidebar = () => {
    if (sidebarIsOpen) setSidebarIsOpen(false);
  };

  return (
    <PermissionRoute
      loader={<GlobalLoader />}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <div data-theme={theme}>
        <UserDetails.Provider value={userInfo[0]}>
          <div className={Style.platform}>
            <Navbar
              sidebarIsOpen={sidebarIsOpen}
              setSidebarIsOpen={setSidebarIsOpen}
            />
            <main>
              <Sidebar
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
                route={sidebarRoutesResearcher}
                theme={theme}
                setTheme={setTheme}
              />

              <div
                className={Style.renderedView}
                onClick={() => sidebarIsOpen && closeSidebar()}
              >
                <Outlet />
              </div>
            </main>
          </div>
        </UserDetails.Provider>
      </div>
    </PermissionRoute>
  );
};

export default MainLayoutResearcher;
