import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Styles
import Style from "./UserNotFound.module.css";

const UserNotFound = ({ setAddUser }) => {
  return (
    <section className={Style.userNotFound}>
      <h6>Utente non trovato nel database</h6>
      <p>
        Non è stato possibile trovare dati relative all'utente corrispondente al
        codice fiscale inserito. Se desideri inserire le informazioni relative
        all'utente clicca sul pulsante "Aggiungi informazioni"
      </p>

      <Button
        className={Style.addInfo}
        onClick={() => {
          setAddUser(true);
        }}
      >
        Aggiungi informazioni
      </Button>
    </section>
  );
};

export default UserNotFound;
