import React, { useContext } from "react";

// Images
import Logo from "../../../Assets/logos/cycad-logo.png";

// Context
import { ThemeContext } from "../../../Contexts/ThemeContext";

// Styles
import Style from "./GlobalLoader.module.css";

const GlobalLoader = () => {

    const { theme } = useContext(ThemeContext);
    return (
        <div className={Style.globalLoader} data-theme={theme}>
            <img src={Logo} alt="" />
        </div>
    );
};

export default GlobalLoader;
