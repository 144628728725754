import React from "react";

// Styles
import Style from "./InfoCitizen.module.css";

const InfoCitizen = ({ data }) => {
  return (
    <div className={Style.infoCitizen}>
      <div className={Style.box}>
        <div className={Style.label}>Nome</div>
        <div className={Style.values}>{data?.name}</div>
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Cognome</div>
        <div className={Style.values}>{data?.surname}</div>
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Data di nascita</div>
        <div className={Style.values}>{data?.bornDate}</div>
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Indirizzo</div>
        <div className={Style.values}>
          {data?.p_street}, {data?.p_snumber}, {data?.p_zipcode}
        </div>
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Regione</div>
        <div className={Style.values}>{data?.p_country}</div>
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Comune</div>
        <div className={Style.values}>{data?.p_municipality}</div>
      </div>
      {/* <div className={Style.box}>
        <div className={Style.label}>Medico di base</div>
        <div className={Style.values}>18mb</div>
      </div> */}
    </div>
  );
};

export default InfoCitizen;
