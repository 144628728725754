import React, { useState, useEffect, useRef } from "react";

// Libraries
import { InputField, Button, MagicModal, Select } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";
import useQuery from "@hybris-software/use-query";

// Components
import ApiResponse from "../../../../../Components/Advanced/ApiResponse/ApiResponse";

// Data
import endpoints from "../../../../../Data/endpoints";

// Styles
import Style from "./AddVaccine.module.css";

const AddVaccine = ({ fiscalCode }) => {
  const [url, setUrl] = useState("");
  const modalRef = useRef();

  const form = useForm({
    inputs: {
      fiscalcode: {
        value: fiscalCode,
      },
      ndose: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      vaccinename: {
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      vaccinetype: {
        value: "Pfizer",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      vaccinedate: {
        value: "30-12-2008 11:00",
        validator: (value) => {
          if (!value || value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          return [true, ""];
        },
      },
      opsan_fiscalcode: {
        value: "MRNMHL95M19F102C",
      },
    },
  });

  const addVaccineApi = useQuery({
    url: url,
    method: "POST",
    clientOptions: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
    onSuccess: (response) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Successo!"
          description="Vaccinazione aggiunta con successo"
          buttonAction={() => modalRef.current.destroy()}
        />
      );
      setUrl(null);
      form.reset();
    },
    onError: (error) => {
      modalRef.current.updateBody(
        <ApiResponse
          title="Errore"
          description={error?.response?.data[0]["Message"]}
          buttonAction={() => modalRef.current.destroy()}
        />
      );
      setUrl(null);
    },
  });

  useEffect(() => {
    console.log(url);
    if (url && url !== "") {
      addVaccineApi.executeQuery();
    }
  }, [url]);

  return (
    <div className={Style.addVaccine}>
      <MagicModal ref={modalRef} />

      <div className={Style.box}>
        <div className={Style.label}>Numero dose</div>
        <InputField
          placeholder="Inserire il numero di dose"
          {...form.getInputProps("ndose")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Nome del vaccino</div>
        <InputField
          placeholder="Inserire nome del vaccino"
          {...form.getInputProps("vaccinename")}
        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Tipo di vaccino</div>
        <Select
          placeholder="Inserire il tipo di vaccino"
          items={["Pfizer", "Moderna", "AstraZeneca", "Janssen", "Altro"]}
          {...form.getInputProps("vaccinetype")}

        />
      </div>
      <div className={Style.box}>
        <div className={Style.label}>Data</div>
        <InputField
          type="datetime-local"
          placeholder="Inserire la Data di vaccinazione"
          {...form.getInputProps("vaccinedate")}
        />
      </div>

      <Button
        className={Style.addButton}
        disabled={!form.isValid()}
        isLoading={addVaccineApi?.isLoading}
        onClick={() => {
          let formData = form.getApiBody();
          let date = formData.vaccinedate.split("T")[0].split("-");
          let time = formData.vaccinedate.split("T")[1].split(":");
          formData.vaccinedate =
            date[2] +
            "-" +
            date[1] +
            "-" +
            date[0] +
            " " +
            time[0] +
            ":" +
            time[1];
          const data = Object.keys(formData)
            .map((key) => {
              return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(formData[key])
              );
            })
            .join("&");
          setUrl(endpoints.addVaccine + "?" + data);
        }}
      >
        Aggiungi
      </Button>
    </div>
  );
};

export default AddVaccine;
