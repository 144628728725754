import { createContext, useState } from "react";

export const ThemeContext = createContext({
    theme: localStorage.getItem("theme"),
    setTheme: () => { },
});

export const ThemeProvider = ({ children }) => {
    // Theme
    const osDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const actualTheme = localStorage.getItem("theme");
    const [theme, setTheme] = useState(actualTheme ? actualTheme : osDark ? "dark" : "light");

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
}