import React, { useState } from "react";

// Components
import Notification from "../Nofitication/Notification";

// Icons
import { HiChevronDown } from "react-icons/hi";
import { RiLogoutCircleLine } from "react-icons/ri";

// Styles
import Style from "./Profile.module.css";

const Profile = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={Style.profile}
      onClick={() => setIsOpen((old) => !old)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Notification />
      <div className={Style.profileTab}>
        <div className={Style.name}>{user?.name + " " + user?.surname}</div>
        <HiChevronDown />
      </div>

      <div className={isOpen ? Style.profileMenu : Style.profileMenuClose}>
        <div
          className={Style.label}
          onClick={() => {
            window.location.replace("https://10.8.0.1/api/spid/logout");
          }}
        >
          <RiLogoutCircleLine /> Logout
        </div>
      </div>
    </div>
  );
};

export default Profile;
