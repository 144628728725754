import React from "react";
import ReactDOM from "react-dom/client";

// Libraries
import { ThemeProvider } from "@hybris-software/ui-kit";
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";
import { AuthProvider } from "@hybris-software/use-auth";

// Components
import App from "./App";

// Styles
import theme from "./Data/theme";
import "./index.css";

const apiClient = generateApiClient({
  baseUrl: "https://10.8.0.1/api/",
  withCredentials: true,
  timeout: 30000,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApiProvider apiClient={apiClient}>
    <AuthProvider apiClient={apiClient} authUrl="userinfo/dashboard">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </ApiProvider>
);
