import React from "react";

// Libraries
import { Link, Outlet } from "react-router-dom";

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => {
  return (
    <div className={Style.layout}>
      <div className={Style.authBackground} />
      <div className={Style.grid}>
        <div className={Style.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
