const endpoints = {
    // Admin
    activateSystem: 'activator/start',
    getStatusSystem: 'activator/getstatus',
    addUser: 'management/adduser',

    // Vaccinatore
    getCitizen: 'userinfo/getuser',
    getAllCitizens: 'management/getallusers',
    addVaccine: 'vaccination/add'

}

export default endpoints;