import React, { useState } from 'react'

// Styles
import Style from './MouseDrag.module.css'

const MouseDrag = ({ children, style }) => {

    const [isDown, setIsDown] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    return (
        <div
            style={style}
            className={Style.draggable}
            onMouseDown={e => {
                setIsDown(true)
                e.currentTarget.classList.add(Style.active)
                setStartX(e.pageX - e.currentTarget.offsetLeft)
                setScrollLeft(e.currentTarget.scrollLeft)
            }}
            onMouseLeave={(e) => { setIsDown(false); e.currentTarget.classList.remove(Style.active) }}
            onMouseUp={(e) => { setIsDown(false); e.currentTarget.classList.remove(Style.active) }}
            onMouseMove={e => {
                if (!isDown) return
                e.preventDefault()
                const x = e.pageX - e.currentTarget.offsetLeft
                const walk = (x - startX) * 1
                e.currentTarget.scrollLeft = scrollLeft - walk
            }}
        >
            {children}
        </div>
    )
}

export default MouseDrag
