// Images
import Logo from "../../../Assets/logos/cycad-logo.png";

// Styles
import Style from "./ViewLoader.module.css";

const ViewLoader = () => {
    return (
        <div className={Style.loader}>
            <img src={Logo} alt="" />
        </div>
    );
};

export default ViewLoader;
