import React, { useState } from "react";

// Libraries
import { InputField, Button } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";

// Styles
import Style from "./SearchBox.module.css";

const SearchBox = ({ setSearch, isLoading, setAddUser }) => {
  const form = useForm({
    inputs: {
      fc: {
        value: "",
        required: false,
        formatter: (value) => value.toUpperCase(),
        validator: (value) => {
          if (value === "") {
            return [false, "Questo campo è obbligatorio"];
          }
          const regex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
          if (!regex.test(value)) {
            return [false, "Codice fiscale non valido"];
          }
          return [true, ""];
        },
      },
    },
  });

  return (
    <section className={Style.searchBox}>
      <h6>Cerca</h6>
      <p>
        Inserisci il codice fiscale del cittadino di cui vuoi ottenere le
        informazioni. Se il cittadino è già presente nel sistema ti verrà
        mostrata la scheda del cittadino, in alternativa potrai procedere con
        l'inserimento dei dati del cittadino.
      </p>
      <div className={Style.searchBar}>
        <InputField
          className={Style.searchInput}
          placeholder="Inserisci il codice fiscale del cittadino"
          {...form.getInputProps("fc")}
        />
        <Button
          disabled={!form.isValid()}
          className={Style.searchButton}
          isLoading={isLoading}
          onClick={() => {
            setAddUser(false);
            setSearch(form.values.fc);
          }}
        >
          Cerca
        </Button>
      </div>
    </section>
  );
};

export default SearchBox;
