import React, { useState } from "react";

// Components
import FilterDate from "../../../../../Components/Advanced/FilterDate/FilterDate";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Icons
import { HiOutlineDownload } from "react-icons/hi";

// Styles
import Style from "./ActionResearcher.module.css";

const ActionResearcher = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <section className={Style.actions}>
            <div className={Style.export}>
                <div>
                    <h6>Esporta Dati</h6>
                    <p>
                        Per eseguire l'export dei dati, inserisci i filtri desiderati e clicca sul tasto “Esporta”.
                    </p>
                </div>
                <Button
                    className={Style.exportButton}
                    onClick={() => { alert("Esporta") }}
                    disabled={false}
                    isLoading={false}
                >
                    Esporta <HiOutlineDownload />
                </Button>
            </div>
        </section>
    );
};

export default ActionResearcher;
