import React, { useContext } from "react";

// Components
import StartSystem from "./Components/StartSysterm/StartSystem";
import SystemActive from "./Components/SystemActive/SystemActive";
import ViewLoader from "../../../Components/Advanced/ViewLoader/ViewLoader";

// Libraries
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";
import { Container } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../Contexts/RoutesContext";

// Data
import endpoints from "../../../Data/endpoints";

const StartSystemAdminView = () => {

  const navigate = useNavigate()
  const { paths } = useContext(RoutesContext);

  const getSystemStatusApi = useQuery({
    url: endpoints.getStatusSystem,
    method: "GET",
    executeImmediately: true,
  });

  return (
    <PermissionRoute
      loader={<ViewLoader />}
      apiLoading={getSystemStatusApi?.isLoading}
      minimumLoadingTime={0}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        return true;
      }}
    >
      <Container>
        <h4>Abilita Sistema</h4>
        {getSystemStatusApi?.isLoading ? (
          <ViewLoader />
        ) : getSystemStatusApi?.response?.data[0].Status === "ACTIVE" ? (
          <SystemActive />
        ) : (
          <StartSystem />
        )}
      </Container>
    </PermissionRoute>
  );
};

export default StartSystemAdminView;
