import React, { useContext } from "react";

// Componnents
import InfoCitizen from "../../../DashboardVaxView/Components/InfoCitizen/InfoCitizen";

// Icons
import { BsCalendar3 } from "react-icons/bs";

// Context
import UserDetails from "../../../../../Contexts/UserContext";

// Styles
import Style from "./Profile.module.css";

const Profile = () => {
  const user = useContext(UserDetails);

  return (
    <section className={Style.userFound}>
      <div className={Style.navigation}>
        <div className={Style.userInfomation}>
          <div className={Style.recap}>
            <h5>
              {user?.name || ""} {user?.surname || ""}
            </h5>
            <p>CF: {user?.fiscalCode}</p>
          </div>
          <div className={Style.date}>
            <BsCalendar3 />
            <div>
              Data inserimento
              <p>{user?.registrationDate}</p>
            </div>
          </div>
        </div>
      </div>

      <InfoCitizen data={user} />
    </section>
  );
};

export default Profile;
